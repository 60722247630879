import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const ToAddress = () => {
  const [isEditable, setIsEditable] = useState(true);
  const [fields, setFields] = useState({
    customerId: null,
    customerName: '',
    street: '',
    cityArea: '',
    country: '',
    vatNumber: '',
  });
  const [initialFields, setInitialFields] = useState(fields);
  const [searchResults, setSearchResults] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://cynosure.digital:9000";

  const toggleEditable = async () => {
    setIsEditable(!isEditable);
    if (isEditable) {
      await saveAddress();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields(prevFields => ({
      ...prevFields,
      [name]: value,
    }));

    if (name === 'customerName' && value.length >= 3) {
      fetchCustomers(value);
    } else if (name === 'customerName' && value.length < 3) {
      setSearchResults([]);
    }
  };

  const fetchCustomers = useCallback(async (customerName) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/customer/search?name=${customerName}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  }, [API_BASE_URL]);

  const handleSelectCustomer = (customer) => {
    setFields({
      customerId: customer.customerId,
      customerName: customer.customerName,
      street: customer.street,
      cityArea: customer.cityArea,
      country: customer.country,
      vatNumber: customer.vatNumber,
    });
    setInitialFields({
      customerId: customer.customerId,
      customerName: customer.customerName,
      street: customer.street,
      cityArea: customer.cityArea,
      country: customer.country,
      vatNumber: customer.vatNumber,
    });
    setSearchResults([]);
    setHighlightedIndex(-1);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex(prevIndex => Math.min(prevIndex + 1, searchResults.length - 1));
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex(prevIndex => Math.max(prevIndex - 1, 0));
    } else if (e.key === 'Enter' && highlightedIndex >= 0) {
      handleSelectCustomer(searchResults[highlightedIndex]);
    }
  };

  const saveAddress = async () => {
    try {
      if (fields.customerId) {
        await axios.put(`${API_BASE_URL}/customer/${fields.customerId}`, fields);
        console.log('Record updated successfully.');
      } else {
        const response = await axios.post(`${API_BASE_URL}/customer`, fields);
        console.log('New record inserted successfully.');
        setFields(prevFields => ({
          ...prevFields,
          customerId: response.data.customerId,
        }));
      }
      setInitialFields(fields);
    } catch (error) {
      console.error('Error saving address:', error);
    }
  };

  return (
    <div className="to-address border bg-sky-50 rounded-md p-4 relative">
      <div className="header pb-4 text-slate-700 font-medium flex flex-row">
        <div className="title basis-4/5 text-left">TO / إلى</div>
        <div className="basis-1/5 print:hidden toggle-edit" onClick={toggleEditable}>
          {isEditable ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 hover:cursor-pointer float-right hover:text-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          ) : (
            <svg className="h-6 w-6 float-right hover:text-slate-400 hover:cursor-pointer" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
          )}
        </div>
      </div>

      <div className="fields">
        {Object.entries(fields).map(([key, value]) => (
          key !== 'customerId' && (
            <div key={key} className="mb-1 field">
              {isEditable ? (
                key === 'customerName' ? (
                  <div className="relative">
                    <input
                      type="text"
                      name={key}
                      className="text-slate-500 w-full text-sm px-2 py-1 border rounded-lg"
                      placeholder={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                      value={value}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      autoComplete="off"
                    />
                    {fields.customerName.length >= 3 && searchResults.length > 0 && (
                      <div className=" search-results absolute top-full left-0 w-full bg-white border border-gray-300 rounded-lg mt-1 shadow-lg" id="searchResults">
                        <ul className="py-2">
                          {searchResults.map((customer, index) => (
                            <li
                              key={customer.customerId}
                              className={`search-result-item p-2 cursor-pointer ${highlightedIndex === index ? 'bg-sky-100' : 'hover:bg-sky-100'}`}
                              onClick={() => handleSelectCustomer(customer)}
                            >
                              {customer.customerName}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                ) : (
                  <input
                    type="text"
                    name={key}
                    className="text-slate-500 w-full text-sm px-2 py-1 border rounded-lg"
                    placeholder={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                    value={value}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                )
              ) : (
                <label className="block">
                  <span className="text-gray-600 ml-2">{value}</span>
                </label>
              )}
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default ToAddress;
